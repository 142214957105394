export enum EventsCategories {
  Index = "MainPage",
  AboutUs = "AboutUs",
  Customers = "Customers",
  EarlyAccess = "EarlyAccess",
}

export enum EventsAction {
  View = "View",
  Submit = "Submit",
  Click = "Click",
}
