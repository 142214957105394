exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-early-access-tsx": () => import("./../../../src/pages/early-access.tsx" /* webpackChunkName: "component---src-pages-early-access-tsx" */),
  "component---src-pages-en-customers-[id]-tsx": () => import("./../../../src/pages/en/customers/[id].tsx" /* webpackChunkName: "component---src-pages-en-customers-[id]-tsx" */),
  "component---src-pages-en-customers-index-tsx": () => import("./../../../src/pages/en/customers/index.tsx" /* webpackChunkName: "component---src-pages-en-customers-index-tsx" */),
  "component---src-pages-en-early-access-tsx": () => import("./../../../src/pages/en/early-access.tsx" /* webpackChunkName: "component---src-pages-en-early-access-tsx" */),
  "component---src-pages-en-for-developers-index-tsx": () => import("./../../../src/pages/en/for-developers/index.tsx" /* webpackChunkName: "component---src-pages-en-for-developers-index-tsx" */),
  "component---src-pages-en-for-developers-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/en/for-developers/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-en-for-developers-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-en-index-tsx": () => import("./../../../src/pages/en/index.tsx" /* webpackChunkName: "component---src-pages-en-index-tsx" */),
  "component---src-pages-en-product-tsx": () => import("./../../../src/pages/en/product.tsx" /* webpackChunkName: "component---src-pages-en-product-tsx" */),
  "component---src-pages-es-customers-[id]-tsx": () => import("./../../../src/pages/es/customers/[id].tsx" /* webpackChunkName: "component---src-pages-es-customers-[id]-tsx" */),
  "component---src-pages-es-customers-index-tsx": () => import("./../../../src/pages/es/customers/index.tsx" /* webpackChunkName: "component---src-pages-es-customers-index-tsx" */),
  "component---src-pages-es-early-access-tsx": () => import("./../../../src/pages/es/early-access.tsx" /* webpackChunkName: "component---src-pages-es-early-access-tsx" */),
  "component---src-pages-es-for-developers-index-tsx": () => import("./../../../src/pages/es/for-developers/index.tsx" /* webpackChunkName: "component---src-pages-es-for-developers-index-tsx" */),
  "component---src-pages-es-for-developers-markdown-remark-frontmatter-slug-tsx": () => import("./../../../src/pages/es/for-developers/{markdownRemark.frontmatter__slug}.tsx" /* webpackChunkName: "component---src-pages-es-for-developers-markdown-remark-frontmatter-slug-tsx" */),
  "component---src-pages-es-index-tsx": () => import("./../../../src/pages/es/index.tsx" /* webpackChunkName: "component---src-pages-es-index-tsx" */),
  "component---src-pages-es-product-tsx": () => import("./../../../src/pages/es/product.tsx" /* webpackChunkName: "component---src-pages-es-product-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

