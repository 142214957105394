import React, { useEffect } from "react";
import ReactGA from "react-ga4";
const RouteChangeTracker = () => {
  useEffect(() => {
    const path = window.location.pathname + window.location.search;
    ReactGA.send({ hitType: "pageview", page: path });
  }, [window.location, document.title]);
  return <></>;
};
export default RouteChangeTracker;
