import React, { useEffect } from "react";
import "../../../styles.scss";
import ReactGA from "react-ga4";
import environments from "../../utils/config/environments";
import RouteChangeTracker from "../RouteTracker/RouteTracker";
import { useGoogleAnalyticsEvent } from "../../hooks/useGoogleAnalitycs";
import {
  EventsAction,
  EventsCategories,
} from "../../utils/enums/googleAnalyticsEvents";

ReactGA.initialize(environments.measurementIdC4c7usCom!);

interface Props {
  children: React.ReactNode;
  location: Location;
}

export const Layout = ({ children, location }: Props) => {
  const params = new URLSearchParams(location.search);

  const [onComingFromSocialNetwork, onComingFromUser] = useGoogleAnalyticsEvent(
    [
      {
        category: EventsCategories.Index,
        action: EventsAction.View,
        actionName: "User coming by social network",
        label: "",
        value: 0,
      },
      {
        category: EventsCategories.Index,
        action: EventsAction.View,
        actionName: "User coming by",
        label: "",
        value: 0,
      },
    ],
  );

  useEffect(() => {
    const isSocialMediaEntrypoint = params.get("social");

    const source = params.get("user_source");

    if (isSocialMediaEntrypoint === "true") {
      onComingFromSocialNetwork(null);
    }

    if (source) {
      onComingFromUser(null, undefined, source);
    }
  }, [params]);
  return (
    <>
      <RouteChangeTracker />
      {children}
    </>
  );
};
