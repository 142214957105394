import { useMemo } from "react";
import {
  EventsAction,
  EventsCategories,
} from "../utils/enums/googleAnalyticsEvents";
import ReactGA from "react-ga4";

export interface Event {
  category: EventsCategories;
  action: EventsAction;
  actionName: string;
  label: string;
  value: number;
}

export function useGoogleAnalyticsEvent<E>(dataToEvents: Event[]) {
  const events = useMemo(() => {
    return dataToEvents.map((event) => {
      const handleEvent = (
        e: E,
        labelParam: string = "",
        actionParam: string = "",
      ) => {
        ReactGA.event({
          category: event.category,
          action: event.action + " " + event.actionName + " " + actionParam,
          label: event.label + " " + labelParam,
          value: event.value,
        });
      };
      return handleEvent;
    });
  }, [dataToEvents]);

  return [...events];
}
