import React from "react";
import Hotjar from '@hotjar/browser';
import { GatsbyBrowser, GatsbySSR } from "gatsby";
import { Layout } from "./src/components/Layout/Layout";
import { Location } from "@gatsbyjs/reach-router";
import "./styles.scss";
const siteId = 5159754;
const hotjarVersion = 6;

Hotjar.init(siteId, hotjarVersion);

type WrapPageElement =
  | GatsbyBrowser["wrapPageElement"]
  | GatsbySSR["wrapPageElement"];

export const wrapPageElement: WrapPageElement = ({ element, props }: any) => {
  return (
    <Location>
      {({ location }) => {
        return (
          <Layout location={location} {...props}>
            {element}
          </Layout>
        );
      }}
    </Location>
  );
};
